/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
import * as React from 'react';
import { IssueType } from '../../types/types';
import BugIcon from './BugIcon';
import CodeSmellIcon from './CodeSmellIcon';
import { IconProps } from './Icon';
import SecurityHotspotIcon from './SecurityHotspotIcon';
import VulnerabilityIcon from './VulnerabilityIcon';

interface Props extends IconProps {
  type: IssueType;
}

export default function IssueIcon({ type, ...iconProps }: Props) {
  switch (type) {
    case 'BUG':
      return <BugIcon {...iconProps} />;
    case 'VULNERABILITY':
      return <VulnerabilityIcon {...iconProps} />;
    case 'CODE_SMELL':
      return <CodeSmellIcon {...iconProps} />;
    case 'SECURITY_HOTSPOT':
      return <SecurityHotspotIcon {...iconProps} />;
    default:
      return null;
  }
}
