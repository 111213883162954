/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
import classNames from 'classnames';
import * as React from 'react';
import { colors } from '../../app/theme';
import Icon, { IconProps } from './Icon';

export default function ClockIcon({ className, ...iconProps }: IconProps) {
  return (
    <Icon className={classNames('icon-clock', className)} {...iconProps}>
      <g fill="#fff" stroke={colors.gray60} transform="matrix(1.4 0 0 1.4 .3 .7)">
        <circle cx="5.5" cy="5.2" r="5" />
        <path d="M5.6 2.9v2.7l2-.5" fillRule="nonzero" />
      </g>
    </Icon>
  );
}
